/**
 * @summary The batting model.
 */
 export class Address
 {
     constructor(
         public address: String = "",
         public balance: number = 0)
     {
     }
 }