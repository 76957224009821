<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="page-next-level">
          <h4 class="title"> Get One </h4>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- Start Terms & Conditions -->
<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-9">
        <div *ngIf="_isLoading" style="text-align: center; height: 200px; padding-bottom: 650px;" >
        <sa-spinner-dotted styles='{"background-color": "transparent", "color":"#7c4fb0", "size": "200px", "height": "200px", "width": "100px"}'></sa-spinner-dotted>
        <p class="text-primary"><b>Loading</b></p>
      </div>
        
        <div *ngIf="!_isLoading" class="alert alert-danger" role="alert"><b> <i style="color:rgb(255, 115, 0)" class="fa fa-exclamation-triangle" aria-hidden="true"></i>  WARNING: Do not send ADA from an exchange. Supported wallets are Daedalus, Yoroi, and ADALite.</b></div>
        <div *ngIf="!_isLoading" class="card shadow border-1 rounded" style="background-color:#1c2530 ">
          <div>
            <div class="row align-items-center">
              <div class="col-lg-5 col-md-6 mt-4 pt-2">
                <img style="width:100%" src="https://storage.googleapis.com/horrocubes_web_assets/card_back_art_preview.png" alt="">
              </div>
              <!--end col-->
        
                <!-- Forms Start -->
                <div class="col-lg-7 col-md-6 mt-4 pt-2" id="forms">
                  <div class="rounded">
                    <div class="p-4 border-bottom">
                      <h4 class="mb-0">Send the specified amount of ADA to the address below: </h4>
                    </div>
                    <div class="p-4">
                      <form>
                        <div class="row">
                          <div class="col-md-12">
                            <div class="mb-3">
                              <label class="form-label">Address:</label>
                              <div class="form-icon position-relative">
                                <i-feather (click)="copyToClipboard(this._session.address.address)" placement="bottom" ngbTooltip="Copy" name="copy" class="fea icon-sm icons" style="cursor: pointer;"></i-feather>
                                <input name="address" type="text" class="form-control ps-5"
                                  readonly [value]="this._session.address.address">
                              </div>
                            </div>
                          </div>
                          <div class="col-md-12">
                            <div class="mb-3">      
                              <label class="form-label">ADA to Send:</label>
                              <div class="form-icon position-relative">
                                <i-feather (click)="copyToClipboard(_amount)" placement="bottom" ngbTooltip="Copy" name="copy" class="fea icon-sm icons" style="cursor: pointer;"></i-feather>
                                <input name="amount" type="text" class="form-control ps-5" readonly [value]="_amount">
                              </div>
                            </div>
                          </div>
                          <!--end col-->
                          <div class="col-md-12">
                            <div class="mb-3">
                              <p class="text-muted">After transferring the funds, please remain on this page until the transaction is confirmed. You will receive a notification.</p>
                              
                              <p class="text-muted">If you encounter any issue, please email us at <span class="text-primary"><b>team@horrocubes.io</b></span> and attach the following code:</p>

                              <div class="mb-3">
                                <label class="form-label">Customer ID:</label>
                                <div class="form-icon position-relative">
                                  <i-feather (click)="copyToClipboard(this._session.key)" placement="bottom" ngbTooltip="Copy" name="copy" class="fea icon-sm icons" style="cursor: pointer;"></i-feather>
                                  <input placement="bottom" ngbTooltip="Please write this code down." name="customerId" type="text" class="form-control ps-5"
                                    readonly [value]="this._session.key">
                                    <br>
                                    <p *ngIf="!_accountFunded" class="text-muted" style="text-align: center;">This session will expire in <span class="text-primary"><b>{{_timeLeft}}</b></span> seconds. Please make the transfer before the timer runs out.</p>
                                    <p *ngIf="_accountFunded" class="text-muted" style="text-align: center;">Account funded; We are minting your <span class="text-primary"><b>Horrocube</b></span>. This could take a few minutes, please wait.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!--end row-->
                      </form>
                      <!--end form-->
                    </div>
                  </div>
                </div>
                <!--end col-->
                <!-- Forms End -->
              <!--end col-->
            </div>
          </div>

        </div>


      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End Terms & Conditions -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
<!-- Empty Modal Content Start -->
<ng-template #ModalContent let-modal>
  <div class="modal-content rounded shadow border-0" style="background-color:#1c2530 ">
    <div class="modal-body py-5">
      <div class="text-center">
        <h5>Account Funded Successfully!</h5>
          <sa-spinner-dotted styles='{"background-color": "transparent", "color":"#7c4fb0", "size": "200px", "height": "200px", "width": "100px"}'></sa-spinner-dotted>
          <p class="text-muted">We are minting your <span class="text-primary"><b>Horrocube</b></span>. This could take a few minutes, please wait.</p>
      </div>
    </div>
  </div>
</ng-template>
<!-- Modal Content End -->