<div class="back-to-home rounded d-none d-sm-block">
  <a routerLink="/index" class="btn btn-icon btn-soft-primary">
    <i-feather name="home" class="icons"></i-feather>
  </a>
</div>

<!-- ERROR PAGE -->
<section class="bg-home d-flex align-items-center">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-8 col-md-12 text-center">
        <img src="https://storage.googleapis.com/horrocubes_web_assets/horrocube_sample.png" class="img-fluid" alt="">
        <div class="text-uppercase mt-4 display-3">404</div>
        <div class="text-capitalize text-dark mb-4 error-page">Page Not Found</div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row">
      <div class="col-md-12 text-center">
        <a routerLink="/index" class="btn btn-primary mt-4 ms-2">Go Home</a>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- ERROR PAGE -->