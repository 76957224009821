import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'transaction-complete',
  templateUrl: './transaction-complete.component.html',
  styleUrls: ['./transaction-complete.component.css']
})

/***
 * TransactionCompleteComponent Component
 */
export class TransactionCompleteComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
