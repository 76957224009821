<!-- Navbar STart -->
<header id="topnav" class="defaultscroll" (window:scroll)="windowScroll()" [class]="navClass"
  [ngClass]="{'tagline-height': sliderTopbar == true}">
  <div class="container">
    <!-- Logo container-->
    <div>
      <a class="logo" routerLink="/index">
        <img src="https://storage.googleapis.com/horrocubes_web_assets/horrologo.png" class="logo-dark-mode" height="80" alt=""> <span style="font-family: UnderWorld; font-size: 12px;"><span style="font-family: UnderWorld; font-size: 16px;">H</span>ORROCUBES</span>
      </a>
    </div>

    <!--e <span *ngIf="!buttonList && !isdeveloper && !shopPages">
       <div class="buy-button">
         <a routerLink="/get-one" class="btn btn-primary">Get One</a>
       </div>
       <div class="buy-button hidden-mobile">
         <a class="btn btn-disabled" placement="bottom" ngbTooltip="Coming Soon">Play</a>
       </div>
     </span>-->
     <!--end login button-->

    <div class="menu-extras">
      <div class="menu-item">
        <!-- Mobile menu toggle-->
        <a class="navbar-toggle" id="isToggle" (click)="toggleMenu()" [ngClass]="{'open': isCondensed === true}">
          <div class="lines">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </a>
        <!-- End mobile menu toggle-->
      </div>
    </div>

    <div id="navigation">
      <!-- Navigation Menu-->
      <ul class="navigation-menu" [class]="navClass">
        <li><a routerLink="/" class="nav-link-ref">Home</a></li>
        <li><a routerLink="/" (click)="scrollToAbout()" class="nav-link-ref">About</a></li>
        <li><a routerLink="/faq" class="nav-link-ref">FAQ</a></li>
        <li><a routerLink="/explore" class="nav-link-ref">Explore</a></li>
        <li><a routerLink="/explore-cards" class="nav-link-ref">Cards</a></li>
        <li><a routerLink="/verify" class="nav-link-ref">Verify</a></li>
      </ul>
      <!--end navigation menu-->
      <div class="buy-menu-btn d-none">
        <a class="btn btn-disabled" placement="bottom" ngbTooltip="Coming Soon">Play</a>
      </div>
      <!--end login button-->
      <!--end login button-->
    </div>
    <!--end navigation-->
  </div>
  <!--end container-->
</header>
<!--end header-->
<!-- Navbar End -->
