// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  apiBaseUrl: "https://api.horrocubes.io/api/",
  ipfsBaseUrl: "https://gateway.pinata.cloud/ipfs/",
  cubePolicyId: "160b85e53e25ef49272c421f04b702bc32184d102865fd1dc8815cde",
  cardPolicyId: "cd743f819701ba9123b4a17d587aaf1342d19fee896453c6ad9c3825"
  };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
