<div class="row justify-content-center">
    <div class="col-lg-2 col-md-2 col-6 text-center">
        <h6>Powered by</h6> 
    </div>
 
    <div class="col-lg-2 col-md-2 col-6 text-center">
        <img placement="top" ngbTooltip="Cardano" src="https://storage.googleapis.com/horrocubes_web_assets/logos/cardano.webp" class="avatar avatar-ex-sm" style="max-width: 100%; cursor: pointer;" alt="">
    </div>
    <div class="col-lg-2 col-md-2 col-6 text-center">
        <img placement="top" ngbTooltip="Plutus" src="https://storage.googleapis.com/horrocubes_web_assets/logos/plutus_logo.png" class="avatar avatar-ex-sm" style="max-width: 100%; cursor: pointer;" alt="">
    </div>
    <div class="col-lg-2 col-md-2 col-6 text-center">
        <img placement="top" ngbTooltip="Daedalus" src="https://storage.googleapis.com/horrocubes_web_assets/logos/dedalus.png" class="avatar avatar-ex-sm" style="max-width: 100%; cursor: pointer;" alt="">
    </div>
</div>

