import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})

/***
 * FAQs Component
 */
export class FaqComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
