<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="page-next-level">
          <h4 *ngIf="this._horrocube != undefined && !_horrocube.newlyMinted" class="title"> Horrocube Information </h4>
          <div *ngIf="this._horrocube != undefined && _horrocube.newlyMinted">
            <h4 class="title">Congratulations! Your Horrocube was minted sucessfully</h4>
            <br>
            <p class="text-muted" style="margin-top: 10px; text-align: center;">It may take a few minutes to show up in your wallet and the blockchain explorer</p>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<section class="section">
  <div class="container">
    <div *ngIf="_horrocube == undefined || _horrocube == null || _horrocube.assetName === '' || _horrocube.assetName === null" style="text-align: center; height: 200px;" >
      <sa-spinner-dotted styles='{"background-color": "transparent", "color":"#7c4fb0", "size": "200px", "height": "200px", "width": "100px"}'></sa-spinner-dotted>
      <p class="text-primary"><b>Loading</b></p>
    </div>

    <div *ngIf="_horrocube.assetName !== ''" class="row justify-content-center">
      <div class="col-lg-9">
        <div class="card shadow border-1 rounded" style="background-color:#1c2530;">
          <div class="card-body">
            <div class="p-4">
              <h4 class="mb-0" style="text-align: center;">Horrocube<span class="text-muted"> #{{parseAssetName(_horrocube.assetName)}}</span></h4>
              <p class="text-muted" style="margin-top: 10px; text-align: center;">Minted on: {{_horrocube.dateOfMint | date}}</p>
            </div>
            <div class="pt-2 border-top" style="text-align: center;">
              <br>
              <img style="width:50%;" [src]="_imageUrl"  alt="" class="rounded box">
            </div>
            <br>
            <br>
            <div class="pt-2" style="text-align: center;">
              <div class="progress" style="width: 50%;margin: 0 auto;"><br>
                <div class="progress-bar position-relative bg-primary" style="width:100%;">
                  <div class="progress-value d-block text-muted h7">Progress: 0%</div>
                </div>
              </div>
            </div>

            <p class="text-muted" style="margin-top: 10px; text-align: center;">Challenges solved: <span class="text-primary">0/10</span>
              <br><span style="cursor: pointer;" placement="bottom" style="cursor: pointer;"  ngbTooltip="Every Horrocube has a unique name. See if you can discover it.">True Name: <span class="text-primary">Unknown.</span></span>
              <br><span style="cursor: pointer;" placement="bottom" style="cursor: pointer;"  ngbTooltip="Every Horrocube earns pasively every 24 hours Insania. This currency can be use to claim other rewards.">Insania: <span class="text-primary">{{_horrocube.stakeReward}}.</span></span></p>

            <br>
              <h5 class="mb-0 text-muted" style="text-align: center;">Description </h5><br>
            <div class="card shadow border-1 rounded">
              <div class="card-body">
                <div class="p-4">
                  <p class="mb-0 text-muted" style="text-align: center;"><i>This Horrocube was forged with a core made of 
                    <span class="text-primary fw-bold" placement="top" style="cursor: pointer;"  ngbTooltip="{{ api.getCoreRarity(_horrocube.core)}}">{{_horrocube.core}}</span>, reinforced with <span class="text-primary fw-bold" placement="top" style="cursor: pointer;"  ngbTooltip="{{ api.getSupportsRarity(_horrocube.supports)}}">{{_horrocube.supports}}</span> supports, adorned with <span class="text-primary fw-bold" placement="top" style="cursor: pointer;"  ngbTooltip="{{ api.getOrnamentsRarity(_horrocube.ornament)}}">{{_horrocube.ornament}}</span>, then attached with a <span class="text-primary fw-bold" placement="top" style="cursor: pointer;"  ngbTooltip="{{ api.getAspectRarity(_horrocube.aspect)}}">{{_horrocube.aspect}}</span> aspect, a <span class="text-primary fw-bold" placement="top" style="cursor: pointer;"  ngbTooltip="{{ api.getMechanismRarity(_horrocube.mechanism)}}">{{_horrocube.mechanism}}</span> mechanism, and a <span class="text-primary fw-bold" style="cursor: pointer;"  ngbTooltip="{{ api.getCommuterRarity(_horrocube.commuter)}}">{{_horrocube.commuter}}</span> commuter. Rejoice upon your descent!.</i></p>
                </div>
              </div>
            </div>

            <br><h5 class="mb-0 text-muted" style="text-align: center;">Rewards </h5>
            <div class="row align-items-center">
              <div class="col-lg-4 col-md-6 mt-4 pt-2" style="text-align: center;">
                <img  placement="top" style="cursor: pointer;"  ngbTooltip="This reward hasn't been unlocked yet. {{ api.getCardRarity(_horrocube.firstCard)}}" style="width:100px;cursor: pointer;" src="https://storage.googleapis.com/horrocubes_web_assets/back_small.png"  alt="" class="rounded box">
                <p class="text-muted" style="margin-top: 10px;">{{parseCardName(_horrocube.firstCard)}}</p>
              
              </div>
              <div class="col-lg-4 col-md-6 mt-4 pt-2" style="text-align: center;">
                <img placement="top" style="cursor: pointer;"  ngbTooltip="This reward hasn't been unlocked yet. {{ api.getCardRarity(_horrocube.secondCard)}}" style="width:100px;cursor: pointer;" src="https://storage.googleapis.com/horrocubes_web_assets/back_small.png"  alt="" class="rounded box">
                <p class="text-muted" style="margin-top: 10px;">{{parseCardName(_horrocube.secondCard)}}</p>
              </div>
              <div class="col-lg-4 col-md-6 mt-4 pt-2" style="text-align: center;">
                <img placement="top" style="cursor: pointer;"  ngbTooltip="This reward hasn't been unlocked yet. {{ api.getCardRarity(_horrocube.lastCard)}}" style="width:100px;cursor: pointer;" src="https://storage.googleapis.com/horrocubes_web_assets/back_small.png"  alt="" class="rounded box">
                <p class="text-muted" style="margin-top: 10px;">{{parseCardName(_horrocube.lastCard)}}</p>
              </div>
            </div>
            <br><h5 class="mb-0 text-muted" style="text-align: center;">Minting Transaction</h5><br>

            <p class="text-muted" style="text-align: center;">You can see the transaction where this Horrocube was minted <a href="https://cardanoscan.io/transaction/{{_horrocube.txId}}" class="text-primary"><b>Here</b></a></p>
            <p class="text-muted" style="text-align: center;">To visualize this Horrocube outside our website go <a href="https://pool.pm/{{_horrocube.policyId}}.{{_horrocube.assetName}}" class="text-primary"><b>Here</b></a></p>


            <br><h5 class="mb-0 text-muted" style="text-align: center;">Policy Details</h5><br>
            <p class="text-muted" style="margin-top: 10px; text-align: center;">For more information on how to validate the policy script and the digital signature, visit our <a routerLink="/faq" class="text-primary"><b>FAQ</b></a></p>
              <!-- Accordions Start -->
              <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-2" [closeOthers]="false">
                <ngb-panel id="ngb-panel-2" cardClass="border-0 rounded shadow mb-2">
                  <ng-template ngbPanelTitle>
                    <div class="border-0 " id="headingone">
                      <h6 placement="top" style="cursor: pointer;"  ngbTooltip="NFT Indetifier. This ID is unique and attached permanently to the asset." class="title mb-0 text-dark">Policy Id  <i-feather name="chevron-down"></i-feather></h6>
                    </div>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <p class="text-muted mb-0 faq-ans"><a href="https://cardanoscan.io/tokenPolicy/{{_horrocube.policyId}}"><span class="text-primary fw-bold">{{_horrocube.policyId}}</span></a></p>
                  </ng-template>
                </ngb-panel>
                <ngb-panel id="ngb-panel-20" cardClass="border-0 rounded shadow mb-2">
                  <ng-template ngbPanelTitle>
                    <div class="border-0 " id="headingone">
                      <h6 placement="top" style="cursor: pointer;"  ngbTooltip="The UTXO used as a parameter of this NFT validator Script." class="title mb-0 text-dark">UTXO Parameter<i-feather name="chevron-down"></i-feather></h6>
                    </div>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <p *ngIf="!_isSingleIdPolicy" class="text-muted mb-0 faq-ans">{{_horrocube.mintingUtxo}}#0</p>
                    <p *ngIf="_isSingleIdPolicy" class="text-muted mb-0 faq-ans">{{_horrocube.mintingUtxo}}</p>
                  </ng-template>
                </ngb-panel>
                <ngb-panel id="ngb-panel-0" cardClass="border-0 rounded shadow mb-2">
                  <ng-template ngbPanelTitle>
                    <div class="border-0 " id="headingone">
                      <h6 placement="top" style="cursor: pointer;"  ngbTooltip="The Policy Script compiled as plutus code." class="title mb-0 text-dark">Policy Script <i-feather name="chevron-down"></i-feather></h6>
                    </div>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <div *ngIf="_policyScript != undefined && _policyScript != null && _policyScript !== ''" >
                      <p class="text-muted mb-0 faq-ans" [innerHtml]="_policyScript"></p>
                      <br>
                      <a *ngIf="!_isSingleIdPolicy" href="https://github.com/Horrocubes/cardano-nft-factory" class="mt-3 h6 text-primary">Find Out More <i class="uil uil-angle-right-b align-middle"></i></a>
                      <a *ngIf="_isSingleIdPolicy" href="https://github.com/Horrocubes/horrocubes-mint-policy-script" class="mt-3 h6 text-primary">Find Out More <i class="uil uil-angle-right-b align-middle"></i></a>
                    </div>
                    <div *ngIf="_policyScript == undefined || _policyScript == null || _policyScript === ''" style="text-align: center; height: 200px;" >
                      <sa-spinner-dotted styles='{"background-color": "transparent", "color":"#7c4fb0", "size": "200px", "height": "200px", "width": "100px"}'></sa-spinner-dotted>
                      <p class="text-primary"><b>Loading</b></p>
                    </div>
                  </ng-template>
                </ngb-panel>
                <ngb-panel id="ngb-panel-1" cardClass="border-0 rounded shadow mb-2">
                  <ng-template ngbPanelTitle>
                    <div class="border-0 " id="headingone">
                      <h6 placement="top" style="cursor: pointer;"  ngbTooltip="Digital signature. You can use this signature to verify that this NFT is authentic." class="title mb-0 text-dark">Signature <i-feather name="chevron-down"></i-feather></h6>
                    </div>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <p class="text-muted mb-0 faq-ans" [innerHTML]="_signatureContent"></p>
                    <br>
                    <a href="https://github.com/Horrocubes/horrocubes-signature-validator" class="mt-3 h6 text-primary">Find Out More <i class="uil uil-angle-right-b align-middle"></i></a>
                    
                  </ng-template>
                </ngb-panel>
              </ngb-accordion>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End Terms & Conditions -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->