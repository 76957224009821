<section class="bg-half-216 d-table w-100" style="background-position: center center; background-image: 'url(https://storage.googleapis.com/horrocubes_web_assets/heading9.png)'; background-repeat: no-repeat; background-size: cover; position: relative; opacity: 0.99">
  <div class="container">
    <div class="row mt-4 align-items-center" >
      <div class="col-lg-5 col-md-5" >
        <div class="title-heading me-lg-4">
          <span class="mb-2" style="font-family: UnderWorld; font-size: 20px; line-height: 2.1em; font-weight: 600; color: #b5b5b5"><span style="font-size: 24px;">I</span>NSIDE THESE CUBES, YOU WILL FIND ULTIMATE REVELATION OR MADNESS AND DAMNATION.

          <BR><BR><span style="font-size: 32px; font-weight: 500;">BE PREPARED.</span> </span>
        </div>
      </div>

      <div class="col-lg-7 col-md-7 mt-4 pt-2 mt-sm-0 pt-sm-0" style="text-align: right;">
        <img style="width: 100%;" src="https://storage.googleapis.com/horrocubes_web_assets/p6.png" alt="">
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!--end section-->
<!-- Hero End -->

<!-- Partners start -->
<section id="about" class="py-4 border-bottom border-top"  style="background-color: #131512;">
  <div class="container">
    <powered-by-logo></powered-by-logo>
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Partners End -->

<!-- What are Horrocubes -->
<section class="section border-bottom" style="background-position: center center; background-image: 'url(https://storage.googleapis.com/horrocubes_web_assets/bg_01.png)'; background-repeat: no-repeat; background-size: cover; position: relative; opacity: 0.99">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div  class="section-title mb-4 pb-2">
          <h4 class="title mb-4">What Are Horrocubes?</h4>
              <p class="text-muted para-desc mb-0 mx-auto"><span
                class="text-primary fw-bold">Horrocubes</span> are essentially puzzle boxes that live in the Cardano blockchain.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row align-items-center">
      <div class="col-lg-5 col-md-6 mt-4 pt-2" style="text-align: center;">
        <img style="width:100%" src="https://storage.googleapis.com/horrocubes_web_assets/example_2_small.png" alt="">
      </div>
      <!--end col-->

      <div class="col-lg-7 col-md-6 mt-4 pt-2">
        <div class="section-title ms-lg-5">
          <h4 class="title mb-4">Solve puzzles and earn rewards!</h4>
          <p class="text-muted">Players will be immersed in a story taking place in the H.P Lovecraft universe. Each time a puzzle is solved, the story advances. The ultimate goal is to unlock all chapters of the story and reach its conclusion.</p>
            
            <p class="text-muted">As players solve puzzles, they will unlock the following rewards:</p>
          <ul class="list-unstyled text-muted">
            <li class="mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Three out of twenty-two unique collectible cards. The cards are randomly selected when the Horrocube is minted. All cubes are guaranteed to have three distinct cards.</li>
            <li class="mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Ten Horrocoins (This coins can be used to claim additional rewards).</li>
            <li class="mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Trophy of completion (obtained when a player completes all ten challenges and solves the cube).</li>
          </ul>

          <p class="text-muted">All the rewards, as well as the Horrocubes, are Alonzo-era NFTs.</p>
          <a routerLink="/"  (click)="scrollToRewards()"  class="mt-3 h6 text-primary">Find Out More <i
              class="uil uil-angle-right-b align-middle"></i></a>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <!--end container-->
</section>
<!--end section-->
<!-- What are Horrocubes End -->

<!-- How It Works  Start -->
<section class="section border-bottom"  style="background-color: #131512;">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pt-2">
          <h4 class="title mb-4">How Does It work?</h4>
              <p class="text-muted para-desc mb-0 mx-auto"><span
                class="text-primary fw-bold">Horrocubes</span> are powered by Plutus Smart Contracts.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    </div>
  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-7 col-md-6 order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="section-title">
          <h4 class="title mb-4">The <span class="text-primary">Horrocubes</span> game runs 100% <br> on the Cardano blockchain.</h4>

          <p class="text-muted">All Horrocubes and the rewards are Alonzo-era NFTs; they are truly unique and can never be reminted. For each cube minted, a smart-contract instance is also created on the blockchain.</p>

          <p class="text-muted">This contract holds the rewards and keeps track of the player's progress. Every puzzle solved awards the player with a Horrocoin, and every three puzzles unlock one of the cube's collectible cards. To unlock all three cards, the player must solve nine out of the ten puzzles. The final puzzle awards the player a trophy as proof of completion.
          </p>

          <p class="text-muted">In a separate contract, there is a final global reward that can be claimed (only once) by the first player that completes all puzzles and take the story to its completion.
          </p>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-5 col-md-6 order-1 order-md-2" style="text-align: center;">
          <div>
            <img class="rounded box" style="width:70%" src="https://storage.googleapis.com/horrocubes_web_assets/fool_preview.png" alt="">
          </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- How It Works  -->

<section id="rewards" class="section border-bottom" style="background-position: center center; background-image: 'url(https://storage.googleapis.com/horrocubes_web_assets/bg_02.png)'; background-repeat: no-repeat; background-size: cover; position: relative; opacity: 0.99">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Rewards</h4>
              <p class="text-muted para-desc mb-0 mx-auto">All our <span class="text-primary"><b>NFTs</b></span> are truly unique as they are created using a Plutus minting script that ties the token minting policy to a specific UTXO.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row align-items-center">
      <div class="col-lg-5 col-md-6 mt-4 pt-2" style="text-align: center;">
        <img class="rounded box" style="width:70%" src="https://storage.googleapis.com/horrocubes_web_assets/mag_preview.png" alt="">
      </div>
      <!--end col-->

      <div class="col-lg-7 col-md-6 mt-4 pt-2">
        <div class="section-title ms-lg-5">
          <h4 class="title mb-4">There are twenty-two unique card designs to be collected.</h4>
          <p class="text-muted"></p>
            
            <p class="text-muted">Every three puzzles, the player unlocks one of the cards inside the cube. Each cube contains 3 of the 22 possible collectible cards. Each card is also an NFT and can be traded freely after the player has unlocked it.
            </p>

            <p class="text-muted">Additionally, every time a player solves a puzzle, he earns <span class="text-primary"><b>Horrocoins</b></span>, these coins can then later be traded in for additional rewards.</p>

            <p class="text-muted">Finally, the first player to solve all the challenges will claim the main reward. Stay tuned for more information.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <!--end container-->
</section>

<!-- How It Work Start -->
<section class="section border-bottom"  style="background-color: #131512;">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pt-2">
          <h4 class="title mb-4">What kind of puzzles will you find?</h4>
              <p class="text-muted para-desc mb-0 mx-auto">The puzzles will require the player to employ different techniques: steganography, cryptography, and lateral thinking.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    </div>
  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-7 col-md-6 order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="section-title">
          <h4 class="title mb-4">Are you up to the challenge?</h4>
          <p class="text-muted">The puzzles increase in difficulty as the game progresses, some of the last puzzles are quite difficult. However, we expect every player to eventually solve all the puzzles. After all the puzzles are solved by at least one player; we will start releasing periodic clues in our Twitter account <a href="https://twitter.com/Horrocubes" class="mt-3 h6 text-primary">@Horrocubes</a>.</p>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-5 col-md-6 order-1 order-md-2">
          <div style="text-align: center;">
            <img src="https://storage.googleapis.com/horrocubes_web_assets/pgbxa.png" alt="">
          </div><br><br>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!-- Testi Start -->



<section class="section  border-bottom pb-0" style="background-position: center center; background-image: 'url(https://storage.googleapis.com/horrocubes_web_assets/bg_03.png)'; background-repeat: no-repeat; background-size: cover; position: relative; opacity: 0.99">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 ">
        <div class="section-title mb-4 pb-2">
          <div class="container-fluid blue-bg">
            <div class="container">
              <!--first section-->
              <h4 class="title mb-4" style="text-align: center;">Roadmap</h4><br><br>
              <div class="row align-items-center how-it-works">
                <div class="col-2 bottom">
                  <div class="circle">1</div>
                </div>
                <div class="col-6">
                  <h5 style="text-align: center;">Q3 2021</h5>
                    
                    <ul class="list-unstyled text-muted">
                      <li class="mb-0"><span class="text-primary h5 me-2"><i
                            class="uil uil-check-circle align-middle"></i></span>Project inception, game mechanics, story, and cryptographic challenges are created. </li>
                      <li class="mb-0"><span class="text-primary h5 me-2"><i
                            class="uil uil-check-circle align-middle"></i></span>Horrocubes and collectible cards art is designed. </li>
                      <li class="mb-0"><span class="text-primary h5 me-2"><i
                            class="uil uil-check-circle align-middle"></i></span>Smart contracts for the game are developed and tested on the Plutus Emulator.</li>
                    </ul>
                </div>
              </div>
              <!--path between 1-2-->
              <div class="row timeline">
                <div class="col-2">
                  <div class="corner top-right"></div>
                </div>
                <div class="col-8">
                  <hr/>
                </div>
                <div class="col-2">
                  <div class="corner left-bottom"></div>
                </div>
              </div>
              <!--second section-->
              <div class="row align-items-center justify-content-end how-it-works">
                <div class="col-6 text-right">
                  <h5 style="text-align: center;">Q4 2021</h5>
                  <ul class="list-unstyled text-muted">
                    <li class="mb-0"><span class="text-primary h5 me-2"><i
                          class="uil uil-check-circle align-middle"></i></span>Frontend and backend with basic functionality are deployed.</li>
                    <li class="mb-0"><span class="text-primary h5 me-2"><i
                          class="uil uil-check-circle align-middle"></i></span>Horrocubes token sale starts, and first Horrocubes are minted.</li>
                    <li class="mb-0"><span class="text-primary h5 me-2"><i
                          class="uil uil-check-circle align-middle"></i></span>The story, challenges, and game design are further polished.</li>
                  </ul>
                </div>
                <div class="col-2 text-center full">
                  <div class="circle">2</div>
                </div>
              </div>
              <!--path between 2-3-->
              <div class="row timeline">
                <div class="col-2">
                  <div class="corner right-bottom"></div>
                </div>
                <div class="col-8">
                  <hr/>
                </div>
                <div class="col-2">
                  <div class="corner top-left"></div>
                </div>
              </div>
              <!--third section-->
              <div class="row align-items-center how-it-works">
                <div class="col-2 text-center top">
                  <div class="circle">3</div>
                </div>
                <div class="col-6">
                  <h5 style="text-align: center;">Q1 2022</h5>
                    <ul class="list-unstyled text-muted">
                      <li class="mb-0"><span class="text-primary h5 me-2"><i
                            class="uil uil-check-circle align-middle"></i></span>Blockchain integration with the website. Users will be able to play the game, track their progress, and trade with other users.</li>
                      <li class="mb-0"><span class="text-primary h5 me-2"><i
                            class="uil uil-check-circle align-middle"></i></span>The 1000 smart-contracts (one for each Horrocube) will be deployed on the blockchain.</li>
                      <li class="mb-0"><span class="text-primary h5 me-2"><i
                            class="uil uil-check-circle align-middle"></i></span>The game starts!.</li>
                    </ul>
                </div>
              </div><br><br>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-12 text-center">
          <div class="section-title mb-4 pb-2">
            <br><br> 
      <h4 class="title mb-4">Do you love NFTs? Puzzles? Horror stories?<br>Are you ready?<br>Madness awaits!</h4><br><br><br><br><br><br>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end col-->
    </div>
    <!--end row-->
    <!--end row-->
  </div>
  <!--end container-->
</section>

<!--end section-->
<!-- Testi End -->

<div class="position-relative">
  <div class="shape overflow-hidden text-light">
    <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!-- Pricing End -->

<!--end section-->
<div class="position-relative">
  <div class="shape overflow-hidden text-footer">
    <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!-- FAQ n Contact End -->


<!-- Back to top -->
<a href="#" [ngxScrollTo]="'#home'" id="back-to-top" class="btn btn-icon btn-primary back-to-top">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->