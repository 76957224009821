<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="page-next-level">
          <h4 class="title"> Session Expired </h4>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- Start Terms & Conditions -->
<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-9">
        <div class="card shadow border-0 rounded">
          <div class="card-body">

            <h1 style="text-align: center;">Your session has expired</h1>
            <br>
            <p class="text-muted">You did not make the payment on time.</p>
            <p class="text-muted"><b>Note:</b></p>
            <p class="text-muted">If you did transfer funds and your Horrocube was not minted, contact us at <span class="text-primary"><b>team@horrocubes.io</b></span> with your Customer ID <span class="text-primary fw-bold">{{id}}</span> and, if possible, the transaction id.</p>
          <br>
            <div class="buy-button" style="text-align: center;">
              <a routerLink="/get-one" class="btn btn-primary">Retry</a>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section><br><br><br><br><br><br><br><br><br><br><br><br>
<!--end section-->
<!-- End Terms & Conditions -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->